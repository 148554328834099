@import "../../global/styles/variables.scss";

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2vh 4vw;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vh;
}

.text {
  font-size: $font-size-medium-large;
  font-weight: 800;
}
