@import "../../../global/styles/variables.scss";

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  font-size: $font-size-medium;
  font-weight: 700;
  padding: 0;
  margin: 0;
  border-radius: 0.5rem;
  background-image: linear-gradient(to right, $color-primary-3, $color-secondary-3);
  overflow: hidden;
}

.card-shutter {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, $color-primary-3, $color-secondary-3);
  border-radius: 0.5rem;
  opacity: 1;
  z-index: 100;
  transition: all 0.3s ease-out;
}

.card-shutter-opened {
  max-height: 0;
}

.card-shutter-closed {
  max-height: 100%;
}

.card-shutter-peek {
  opacity: 0;
}

.card-title-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.card-number {
  font-size: $font-size-medium;
  font-weight: 600;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
}

.highlighted-blue {
  color: $font-color-light;
  background: $color-team-blue;
}

.highlighted-red {
  color: $font-color-light;
  background: $color-team-red;
}

.highlighted-both {
  color: $font-color-light;
  background-image: linear-gradient(to right, $color-team-blue 49%, $color-team-red 51%);
}
