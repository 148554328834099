@import "../../styles/variables.scss";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-medium;
  font-weight: 700;
  padding: 1vh 1vw;
  text-align: center;
  vertical-align: middle;
  border: 0;
  border-radius: 1rem;
  background-color: $color-primary-2;
  box-shadow: $box-shadow-button;
  transition: background-color 0.1s ease-out, transform 0.05s, box-shadow 0.05s;
  gap: 0.5vw;

  &:hover:not(.button-disabled) {
    background-color: $color-secondary-2;
    cursor: pointer;
  }

  &:active:not(.button-disabled) {
    transform: translateY(5%);
    box-shadow: $box-shadow-button-pushed;
  }

  svg {
    font-size: $font-size-large;
  }
}
