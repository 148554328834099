$color-background: #fefefe;
$color-primary-1: #4c933b;
$color-secondary-1: #98c13d;
$color-primary-2: #ffed4d;
$color-secondary-2: #ffd000;
// $color-primary-1: #1f2092;
// $color-secondary-1: #3149d1;
// $color-primary-2: #7d26f0;
// $color-secondary-2: #6f00ff;
$color-primary-3: #4ca044;
$color-secondary-3: #a6c56c;

$color-danger-primary: #e4371c;
$color-danger-secondary: #c73019;

$color-status-green: #5dca14;
$color-status-gray: #b9b9b9;

$color-error: #ff0000;
$color-dark: #000000;
$color-gray: #666666;
$color-light: #fefefe;

$color-team-red: #8f2b1c;
$color-team-blue: #3284a3;

@function calculate-font-size($value) {
  @return calc((#{$value}vh + #{$value}vw / 2) / 2);
}

$font-size-very-small: calculate-font-size(1.3);
$font-size-small: calculate-font-size(1.4);
$font-size-medium-small: calculate-font-size(1.5);
$font-size-medium: calculate-font-size(1.8);
$font-size-medium-large: calculate-font-size(2);
$font-size-large: calculate-font-size(2.5);
$font-size-very-large: calculate-font-size(4);
$font-size-log-highlight: calculate-font-size(1.6);

$font-color-dark: #000000;
$font-color-light: #ffffff;
$font-color-gray: #666666;
$font-color-light-gray: #e5e7eb;

$background-image: url("../../assets/background-image.png");

$background-default: radial-gradient(circle at 50% 60%, rgb(117, 175, 104) 0%, rgb(43, 90, 32) 100%);
$background-red: radial-gradient(circle at 50% 60%, rgb(231, 102, 60) 0%, rgb(72, 12, 2) 100%);
$background-blue: radial-gradient(circle at 50% 60%, rgb(91, 174, 215) 0%, rgb(5, 60, 84) 100%);

:export {
  background-default: $background-default;
  background-red: $background-red;
  background-blue: $background-blue;
}

$spinner-size-medium: calculate-font-size(15);
$spinner-size-large: calculate-font-size(25);

$box-shadow-button: 0 3px 3px 1px rgba($color-dark, 0.3);
$box-shadow-button-pushed: 0 1px 3px 0 rgba($color-dark, 0.4);
$box-shadow-card: 0 3px 3px 2px rgba($color-dark, 0.3);
