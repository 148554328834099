@import "../../../global/styles/variables.scss";

.container {
  position: absolute;
  left: calc(100% + 1vw);
  top: -1vh;
  width: auto;
  height: auto;
  padding: 0.6vw;
  gap: 0.5vw;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-background;
  border-radius: 1rem;

  Button {
    white-space: nowrap;
    font-size: $font-size-medium;
    padding: 0.8vh 0.8vw;
    border-radius: 0.7rem;
  }
}

.button-danger {
  background-color: $color-danger-primary;

  &:hover:not(.button-disabled) {
    background-color: $color-danger-secondary;
  }
}

.container:before {
  content: "";
  position: absolute;
  left: -0.5vw;
  right: 0;
  width: 0;
  height: 0;
  border-top: $font-size-medium-small solid transparent;
  border-bottom: $font-size-medium-small solid transparent;
  border-left: 0 solid transparent;
  border-right: $font-size-medium-small solid $color-background;
}
