@import "../../global/styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.dashboard {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2vh 2vw;
}

.title {
  font-size: $font-size-large;
  font-weight: 800;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 3vh 2vw;
  gap: 2vh;
  margin: auto auto;
  height: 40vh;
  width: 16vw;
  background-color: $color-background;
  border-radius: 1rem;
  box-shadow: $box-shadow-card;

  > form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 4.5vh;
  }
}
