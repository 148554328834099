@import "./global/styles/variables.scss";

* {
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.5px;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
  font-size: $font-size-medium;
  user-select: none;
  overflow: hidden;
}

input {
  font-size: $font-size-medium-small;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
  font-weight: inherit;
}

h1 {
  font-size: $font-size-large;
}

h2 {
  font-size: $font-size-medium-large;
}

h3 {
  font-size: $font-size-medium;
}

h3 {
  font-size: $font-size-medium-small;
}

::-webkit-scrollbar {
  width: 0.5vw;
}

// scrollbar track
::-webkit-scrollbar-track {
  opacity: 0; // invisible
}

// scrollbar handle
::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border-radius: 1rem;

  &:hover {
    background-color: #9e9e9e;
  }
}
