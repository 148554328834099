@import "../../global/styles/variables.scss";

.page-background {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: $background-image;
  background-size: cover;
  box-shadow: inset 0 0 200px -100px $color-dark; // vignete
}

.page-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2vh;
  position: relative;
  top: -10vh;

  * {
    letter-spacing: 2px;
  }
}

.title {
  font-size: $font-size-very-large;
  font-weight: 700;
}

.text {
  font-size: $font-size-large;
}
