@import "../../../global/styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vh;
  width: 100%;
  padding: 0 1vw;
  font-size: $font-size-medium;
  text-align: center;
}

.team-select {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 1vh;
  width: 100%;
}
