@import "../../../global/styles/variables.scss";

.drop-area {
  width: 100%;
  height: 30vh;
  display: grid;
  grid-auto-rows: 1fr;
  gap: 0.2vh;
  overflow: scroll;
  scrollbar-width: none;
  padding: 1vh 0.5vw;
  font-size: $font-size-medium;
  border: 2px dashed $color-gray;
  cursor: pointer;
  overflow: scroll;
  scrollbar-width: none;
}

.empty {
  width: 100%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1vh 0.5vw;
  font-size: $font-size-medium-large;
  border: 2px dashed $color-gray;
  cursor: pointer;
  overflow: scroll;
  scrollbar-width: none;
}

.image {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.upload-icon {
  font-size: calc($font-size-very-large * 1.5);
  margin-top: 2vh;
}
