@import "../../global/styles/variables.scss";

.hint {
  display: flex;
  flex-direction: column;
  gap: 0.5vh;

  p {
    text-align: justify;
    font-size: $font-size-medium;
  }
}

.drop-area-container {
  width: 100%;
  height: auto;
}

.counter {
  align-self: last baseline;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.2vw;
}
