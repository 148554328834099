@import "../../global/styles/variables.scss";

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3vh 2vw;
  height: 35vh;
  width: 15vw;
  background-color: $color-background;
  border-radius: 1rem;
  box-shadow: $box-shadow-card;
}

.red {
  background: $color-team-red;
}

.blue {
  background: $color-team-blue;
}

.players {
  max-height: 70%;
  overflow: scroll;
  scrollbar-width: none;
}

.player {
  display: inline-block;
  color: $font-color-light;
  font-weight: 500;
  border: 2px solid $color-light;
  text-shadow: 2px 2px 5px black;
  margin: 0.4vh 0.2vw;
  padding: 0.4vh 0.4vw;
  border-radius: 0.5rem;
}
