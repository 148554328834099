@import "../../global/styles/variables.scss";

.container {
  width: 55vw;
  height: 80vh;
  display: grid;
  grid-auto-rows: 1fr;
  justify-content: center;
  align-content: center;
  gap: 1vh;
}
