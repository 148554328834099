@import "../../../global/styles/variables.scss";

.copy-button {
  font-size: $font-size-medium-small;
  font-weight: inherit;
  background-color: inherit;
  border: none;
  cursor: pointer;
  margin: auto 0.5vw;
  padding: 0;
  padding-block: 0;
  padding-inline: 0;
  position: relative;

  > svg {
    text-align: center;
    vertical-align: middle;
    margin-right: 0.3vw;
    margin-bottom: 0.4vh;
  }
}
