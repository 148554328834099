@import "../../../global/styles/variables.scss";

.pick-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  background-color: $color-primary-2;
  border: none;
  border-radius: 5rem;
  font-size: $font-size-medium-large;
  margin: 0;
  padding: 0.5vh;
  box-shadow: 2px 2px 2px 2px rgba($color-dark, 0.4);
  transition: all 0.1s ease-in-out;
  color: #222;
  cursor: pointer;

  &:hover {
    background-color: $color-secondary-2;
  }
}
