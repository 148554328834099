@import "../../global/styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.connect-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vh;
  font-weight: 500;
  padding: 3vh 0.6vw;
}

.invite-text {
  font-weight: 700;
  font-size: $font-size-medium-small;
  color: $color-primary-1;
  padding: 0 0.3vw;
  text-align: justify;
}

.room-code {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.room-id {
  user-select: text;
  font-size: $font-size-medium-small;
  width: 10vw;
  overflow: scroll;
  scrollbar-width: none;
  white-space: nowrap;
  display: inline-block;
  border-radius: 0.6rem;
  padding: 0.9vh 0.6vw;
  box-shadow: inset 0 0 4px 2px rgba($color-dark, 0.1);
}

.settings {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100%;
  background-color: $font-color-light-gray;
  border-bottom-right-radius: 1rem;
}

.section {
  padding-top: 3vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vh;
}

.button-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5vh;
}

.button {
  margin-bottom: 1vh;

  &:active {
    transform: none;
  }
}

.players {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2vh;
}
