@import "../../global/styles/variables.scss";

.dashboard-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 7vh;
  background: transparent;
  padding: 0 4vw;
}

.button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1vw;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  gap: 0.2vw;
}
