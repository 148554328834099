@import "../../../../global/styles/variables.scss";

.card {
  position: absolute;
  bottom: 15%;
  left: 5.85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: $font-size-medium;
  font-weight: 700;
  padding: 0;
  margin: 0;
  border-radius: 0.5rem;
  overflow: hidden;
}

.card-title-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.card-number {
  font-size: $font-size-medium;
  font-weight: 600;
}

.image-container {
  width: 11vw;
  height: 22vh;
}

.image {
  width: 100%;
  height: 100%;
}

.highlighted-blue {
  color: $font-color-light;
  background: $color-team-blue;
}

.highlighted-red {
  color: $font-color-light;
  background: $color-team-red;
}
