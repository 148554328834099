@import "/src/global/styles/variables.scss";

.background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -99;
  box-shadow: inset 0 0 250px -100px $color-dark; // vignete
}

.background-default {
  background: $background-default;
}

.background-red {
  background: $background-red;
}

.background-blue {
  background: $background-blue;
}

.background-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-image: $background-image;
  mix-blend-mode: overlay;
  z-index: -99;
}
