@import "../../../global/styles/variables.scss";

.container {
  display: flex;
  align-items: center;
  width: 100%;
}

.player-name {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5vh 0.5vw;
  text-align: center;
  vertical-align: middle;
  border: 4px solid transparent;
  border-radius: 0.8rem;
  background-color: transparent;
  transition: transform 0.05s, border 0.1s;
  font-weight: 600;
  font-size: $font-size-medium;

  & > svg {
    position: absolute;
    right: -20%;
    font-size: $font-size-medium;
  }
}

.player-name-interactible:hover {
  cursor: pointer;
  opacity: 0.7;
}
