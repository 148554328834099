@import "../../../global/styles/variables.scss";

.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 800;
  background-color: rgba($color-dark, 0.7);
}

.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  padding: 2vh 1.2vw;
  z-index: 900;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vh;
  min-width: 20vw;
  min-height: 15vh;
  background-color: $color-background;
  border-radius: 1rem;
  box-shadow: 0 4px 4px 4px rgba($color-dark, 0.2);
}

.header {
  font-weight: 700;
  font-size: $font-size-medium-large;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: $font-size-medium;
}

.footer {
  margin-top: 2vh;
  width: 100%;
  display: flex;
  align-items: center;
}
