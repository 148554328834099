@import "./variables.scss";

.flex-container {
  display: flex;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vw;
}

.flex-vertical {
  @extend .flex;
  flex-direction: column;
}

.form-vertical {
  @extend .flex-vertical;
  justify-content: space-between;
  gap: 2vh;
}

.text-center {
  text-align: center;
  vertical-align: middle;
}

.text-error {
  font-size: $font-size-medium-small;
  color: $color-error;
}
