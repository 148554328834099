@import "../../global/styles/variables.scss";

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  gap: 2vh;
  padding: 2vh 1vw;
  border-radius: 1rem;
  box-shadow: $box-shadow-card;
  font-size: $font-size-medium-large;
  font-weight: 600;
  height: auto;
  width: 21vw;
  background-color: $color-background;
  border: none;
}

.options {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vh;
  margin-bottom: 4vh;
}

.option-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5vh;
}

.option-button {
  margin-left: 1.2vw;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  gap: 0.2vw;
}
