@import "../../global/styles/variables.scss";

.grid-container {
  display: grid;
  grid-template-columns: 20vw 30vw 30vw 20vw;
  grid-template-rows: 100vh;
}

.dashboard {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2vh 2vw;
}

.form-join {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
}

.form-create {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
}

.text {
  font-size: $font-size-large;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1vh 1vw;
  margin: auto auto;
  background-color: $color-background;
  border-radius: 1rem;
  box-shadow: $box-shadow-card;

  > form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 6vh;
  }
}

.card-join {
  height: 38vh;
  width: 18vw;
}

.card-create {
  height: 38vh;
  width: 18vw;
}
