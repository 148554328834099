@import "../../global/styles/variables.scss";

.card {
  position: absolute;
  top: 54%;
  right: 4%;
  height: 40vh;
  width: 15vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1.5vh;
  gap: 1.5vh;
  background-color: $color-background;
  border-radius: 1rem;
  box-shadow: $box-shadow-card;
  font-size: $font-size-medium-small;
  opacity: 1;
  overflow: hidden;
}

.card-empty {
  opacity: 0.4;
}

.header {
  font-size: $font-size-medium;
  font-weight: 700;
  align-self: center;
  flex-shrink: 0;
  letter-spacing: 0.5px;
  border-bottom: 2px solid $font-color-light-gray;
  text-transform: uppercase;
  padding-bottom: 0.5vh;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
  width: 100%;
  gap: 1px;
}

.activity-row {
  width: 100%;
  padding: 0.5vh 0.2vw;
  letter-spacing: 0.2px;

  span {
    letter-spacing: 0.2px;
  }
}

.background-red {
  background: #ffe7d5;
}

.background-blue {
  background: #cff9ff;
}

.red {
  font-weight: 700;
  color: #d05139;
  font-size: $font-size-log-highlight;
}

.blue {
  font-weight: 700;
  color: #3499d3;
  font-size: $font-size-log-highlight;
}

.card-number {
  font-weight: 700;
  color: $font-color-gray;
  font-size: $font-size-log-highlight;
}
