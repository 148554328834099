@import "../../../global/styles/variables.scss";

.circle {
  width: $font-size-medium-small;
  height: $font-size-medium-small;
  border-radius: 50%;
}

.connected {
  background-color: $color-status-green;
}

.disconnected {
  background-color: $color-status-gray;
}
