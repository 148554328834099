@import "../../styles/variables.scss";

.input-group {
  position: relative;
  margin-top: 1vh;
}

.input-field {
  margin: 0;
  background: transparent;
  border: none;
  outline: none;
  font-size: $font-size-medium;
  font-weight: bold;
  height: 4vh;
  box-shadow: none;
  transition: border-color 0.2s;
}

.input-field-focused {
  font-weight: 700;
  border-width: 3px;

  ~ .input-label {
    display: block;
    position: absolute;
    top: -1.5vh;
    transition: 0.2s;
    font-size: $font-size-medium-small;
    font-weight: bold;
  }
}

.input-field-focused-regular {
  @extend .input-field-focused;
}

.input-exists {
  @extend .input-field-focused-regular;
  border-image: linear-gradient(to right, $color-primary-1, $color-secondary-1) 1;

  ~ .input-label {
    color: $color-primary-1;
  }
}

.input-field-regular {
  border-bottom: 2px solid $font-color-gray;

  &::placeholder {
    color: transparent;
  }

  &:focus {
    @extend .input-field-focused-regular;
  }

  &:not(:placeholder-shown) {
    @extend .input-exists;
  }
}

.input-field-focused-error {
  @extend .input-field-focused;
  border-image: none;
  border-bottom: 2px solid $color-error;
}

.input-field-error {
  border-bottom: 2px solid $color-error;
  border-image: none;

  ~ .input-label {
    color: $color-error;
  }

  &:focus {
    @extend .input-field-focused-error;
  }

  &:not(:placeholder-shown) {
    @extend .input-field-focused-error;
  }
}

.input-label {
  display: block;
  display: block;
  position: absolute;
  top: 0.8vh;
  transition: 0.2s;
  font-size: $font-size-medium;
  font-weight: bold;
  pointer-events: none;
}

.input-label-regular {
  color: $font-color-gray;
}

.input-label-error {
  color: $color-error;
}

.input-error-message {
  display: block;
  position: absolute;
  color: $color-error;
  font-size: $font-size-medium-small;
  padding: 0.6vh 0.2vw;
}
