@import "../../styles/variables.scss";

$panel-width: 16vw;

.sliding-panel {
  background-color: $color-background;
  display: block;
  position: fixed;
  height: 100vh;
  width: $panel-width;
  z-index: 1000;
}

.left {
  top: 0;
  left: 0;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  box-shadow: 3px 3px 3px 2px rgba($color-dark, 0.3); // same as $box-shadow-card, but positive h-offset
}

.left-enter {
  transform: translateX(-100%);
}

.left-enter-active {
  transition: transform 0.3s;
  transform: translateX(0);
}

.left-exit {
  transform: translateX(0);
}

.left-exit-active {
  transition: transform 0.3s;
  transform: translateX(-100%);
}

.right {
  top: 0;
  left: calc(100vw - $panel-width);
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  box-shadow: -3px 3px 3px 2px rgba($color-dark, 0.3); // same as $box-shadow-card, but negative h-offset
}

.right-enter {
  transform: translateX(100%);
}

.right-enter-active {
  transition: transform 0.3s;
  transform: translateX(0);
}

.right-exit {
  transform: translateX(0);
}

.right-exit-active {
  transition: transform 0.3s;
  transform: translateX(100%);
}
