@import "../../global/styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.team-select {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: $font-size-medium-large;
  font-weight: 500;
  padding: 3vh 0.5vw;
}

.settings {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
  background-color: $font-color-light-gray;
  border-bottom-left-radius: 1rem;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 3.5vh;
}

.section {
  padding: 3vh 0 4vh 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4vh;
}

.language {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}
