@import "../../global/styles/variables.scss";

.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 800;
  background-color: rgba($color-dark, 0.7);
}

.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  z-index: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50vw;
  max-height: 80vh;
}

.image-container {
  width: 100%;
  height: 100%;
  max-width: 50vw;
  max-height: 80vh;
  overflow: hidden;
  border-radius: 2rem;
}

.image {
  max-width: 50vw;
  max-height: 80vh;
}
