@import "../../../global/styles/variables.scss";

.button {
  display: flex;
  justify-content: center;
  width: 9vw;
  text-align: center;
  padding: 1vh 1vw;
  border-radius: 1rem;
  box-shadow: $box-shadow-button;
  font-size: $font-size-medium;
  font-weight: 600;
  background-color: none;
  border: none;
  transition: background-color 0.2s, transform 0.05s, box-shadow 0.05s;

  &:hover:not(.button-disabled) {
    cursor: pointer;
  }

  &:active:not(.button-disabled) {
    transform: translateY(5%);
    box-shadow: $box-shadow-button-pushed;
  }
}

.red {
  background: $color-team-red;

  &:hover:not(.button-disabled) {
    background: rgba($color-team-red, 0.9);
  }
}

.blue {
  background: $color-team-blue;

  &:hover:not(.button-disabled) {
    background: rgba($color-team-blue, 0.9);
  }
}

.none {
  background: $color-primary-2;

  &:hover:not(.button-disabled) {
    background: rgba($color-secondary-2, 0.9);
  }
}
